import Loadable from '@loadable/component';
import React, { Component } from 'react';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Layout from '../layout';

import CTA2 from '../components/CTA';

const LoadableCalculator = Loadable(() => import('../components/csscalculator-next'));

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="CSS Selector Specificity Calculator"
          keywords={['']}
          pathname={this.props.location.pathname}
          description="Quickly calculate any CSS selector specificity score along with explanations. With full support for complex CSS selectors."
          ogFileName="csscalc"
          ogCustomPath="/og-custom/"
          imageDescription="CSS Specificity Calculator as text above an overview of a selector with a specificity of 1.2.3"
        />
        <PageHeader>
          <h1>CSS Selector Specificity</h1>
          <h2>Calculate the specificity of selectors all the way up to CSS Selectors level 4</h2>
        </PageHeader>
        <LoadableCalculator />

        <div className="page childPadding" style={{ marginTop: '4rem' }}>
          <h3>What is CSS selector specificity?</h3>
          <p>
            If an element is targeted by multiple selectors, browser use the specificity of the selector to determine
            which styling to apply. Each selector has a specificity that is determined by what you use in that selector:
            elements, ID's, classes etc. This specificity is split up in different levels.
          </p>
          <p>
            A single value in a higher level is more important than all levels below it. For example, you can have a
            1000 class names, and a selector with a single ID would still be more specific.
          </p>
          <h3>Gotcha's when dealing with CSS selector specificity issues</h3>
          <ul>
            <li>
              The universal selector <code>*</code> has no specificity.
            </li>
            <li>
              properties with <code>!important</code> are applied even if the selector is less specific.
            </li>
            <li>
              &hellip;Except for the properties with <code>!important</code> in same or a higher specificity selectors
              later in your CSS.
            </li>
            <li>
              &hellip;Except for the <code>style=""</code> attribute on an element.
            </li>
            <li>
              If you use a pseudo class like <code>:not()</code> or <code>:is()</code>, the selector inside it with the
              highest specificity determines the specificity.
            </li>
            <li>
              Anything in a <code>:where()</code> has no specificity.
            </li>
            <li>
              For <code>:nth-child()</code>, <code>:nth-last-child()</code>, <code>:host()</code> and{' '}
              <code>:host-context()</code> pseudo-classes the specificity is the combination of the pseudo-class itself
              (0,1,0) plus the most specific of the selectors in them.
            </li>
            <li>
              For the <code>::slotted()</code> pseudo-element the specificity is the combination of the pseudo-element
              itself (0,0,1) plus the specificity of the selector inside it.
            </li>
            <li>
              Child or sibling selectors (<code>&gt;, ~, +</code>) add no specificity.
            </li>
          </ul>
        </div>
        <CTA2 />
      </Layout>
    );
  }
}

export default Page;
